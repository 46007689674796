









import { defineComponent } from '@nuxtjs/composition-api'

export const ConfettiNotice = defineComponent({})

export default ConfettiNotice
