export interface MemberInfo {
  name?: string
  host?: boolean
}

export interface Member {
  id: string
  info: MemberInfo
}

export interface MembersInfo {
  [id: string]: MemberInfo
}

export type TokenPayload = {
  code: string
  playerId: string
} & MemberInfo

export enum JoinErrorField {
  name = 'name',
  code = 'code',
}

export interface JoinError {
  message: string
  field: JoinErrorField
}
