



























































































import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api'
import ky from 'ky/umd'

import ConfettiUserProfile from '~/assets/ConfettiUserProfile.svg?inline'
import ConfettiKeyPad from '~/assets/ConfettiKeyPad.svg?inline'
import ConfettiAddTeam from '~/assets/ConfettiAddTeam.svg?inline'
import { ConfettiButton } from '~/components/ConfettiButton.vue'
import { TokenPayload, JoinError, JoinErrorField } from '~/utils/types'

const CODE_LENGTH = 5

export const ConfettiBuzzerForm = defineComponent({
  components: {
    ConfettiButton,
    ConfettiUserProfile,
    ConfettiKeyPad,
    ConfettiAddTeam,
  },
  setup(_, { root }) {
    const slug = computed(() => root.$route.params.slug)
    const code = ref<string>()
    const name = ref<string>()
    const teamName = ref<string>()
    const isNameValid = computed(() => Boolean(name.value?.length))
    const isTeamNameValid = computed(() => Boolean(teamName.value?.length))
    const isCodeValid = computed(() => code.value?.length === CODE_LENGTH)
    const codeErrorMessage = ref<string>()
    const nameErrorMessage = ref<string>()
    const teamNameErrorMessage = ref<string>()
    const isPendingJoin = ref(false)

    // initialize code with slug
    watch(
      slug,
      (value) => {
        if (!value) return
        code.value = value.toUpperCase()
      },
      { immediate: true },
    )

    const startGame = ({ code }: TokenPayload) => {
      if (root.$route.params.slug === code) root.$router.go(0)
      else root.$router.push({ path: code })
    }

    const join = async () => {
      if (!code.value || !teamName.value || !name.value) return

      const computedName = `${name.value} - ${teamName.value}`

      try {
        isPendingJoin.value = true
        const json = { name: computedName }
        const result = await ky
          .post(`/api/${code.value}/join`, { json })
          .json<TokenPayload>()
        startGame(result)
      } catch (err) {
        if (err instanceof ky.HTTPError && err.response.status === 400) {
          const { message, field }: JoinError = await err.response
            .json()
            .catch(() => err)
          if (field === JoinErrorField.name || slug.value) {
            nameErrorMessage.value = message
          } else if (field === JoinErrorField.code) {
            codeErrorMessage.value = message
          } else {
            alert(err.message)
          }
        } else {
          alert(err.message)
        }
      } finally {
        isPendingJoin.value = false
      }
    }

    // reset errors
    watch(code, () => {
      codeErrorMessage.value = undefined
    })

    watch(name, () => {
      nameErrorMessage.value = undefined
    })

    watch(teamName, () => {
      teamNameErrorMessage.value = undefined
    })

    const onNameValidation = ({ target }: Event) => {
      const input = target as HTMLInputElement
      input.setCustomValidity(
        input.validity.valueMissing ? 'Name is required' : '',
      )
    }

    const onTeamNameValidation = ({ target }: Event) => {
      const input = target as HTMLInputElement
      input.setCustomValidity(
        input.validity.valueMissing ? 'Team name is required' : '',
      )
    }

    const onCodeValidation = ({ target }: Event) => {
      const input = target as HTMLInputElement

      if (input.validity.valueMissing || input.validity.tooShort) {
        input.setCustomValidity('Code should be 5 letters and numbers')
      } else {
        input.setCustomValidity('')
      }
    }

    const onInput = ({ target }: Event) =>
      (target as HTMLInputElement).checkValidity()

    return {
      slug,
      maxlength: CODE_LENGTH,
      isPendingJoin,
      name,
      nameErrorMessage,
      teamName,
      teamNameErrorMessage,
      codeErrorMessage,
      isCodeValid,
      isNameValid,
      isTeamNameValid,
      code,
      join,
      onInput,
      onCodeValidation,
      onNameValidation,
      onTeamNameValidation,
    }
  },
})

export default ConfettiBuzzerForm
