


















import { defineComponent } from '@nuxtjs/composition-api'
import { ConfettiLoader } from '~/components/ConfettiLoader.vue'

interface Props {
  loading?: boolean
  primary?: boolean
  secondary?: boolean
  center?: boolean
}

export const ConfettiButton = defineComponent<Props>({
  props: {
    loading: Boolean,
    primary: Boolean,
    secondary: Boolean,
    center: Boolean,
  },
  components: {
    ConfettiLoader,
  },
})

export default ConfettiButton
