











import { defineComponent } from '@nuxtjs/composition-api'

export const ConfettiLoader = defineComponent({})

export default ConfettiLoader
